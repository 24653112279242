import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { processActions } from '../store/actions';
const useTransition = (timeout = 0) => {
    const dispatch = useDispatch();
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(processActions.finishTransition());
        }, timeout);
        return () => {
            clearTimeout(timer);
        };
    }, []);
};

export default useTransition;
