import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import { Grid } from "@mui/material";
import style from "./testimonials.module.scss";
import SwiperItem from "./SwiperItem";
import { useQuery } from "@tanstack/react-query";
import { cdnService } from "../../../services";

export default function Testimonials() {
  const { data: testimonials } = useQuery({
    queryKey: ["getProfile"],
    queryFn: cdnService.getTestimonials,
    staleTime: 300000,
  });

  const pagination = {
    clickable: true,
  };
  return (
    <Grid
      container
      mt={9}
      direction={{ md: "column", lg: "row" }}
      className={style["revenue"]}
    >
      <Grid
        item
        xs={12}
        mb={4}
        justifyContent="center"
        className={style["primary-header"]}
      >
        AFFILIATE TESTIMONIALS
      </Grid>
      <Grid item xs={12} justifyContent="center">
        <Swiper
          navigation
          autoplay={{
            delay: 3000,
          }}
          cssMode={true}
          loop={true}
          slidesPerView={1}
          pagination={pagination}
          mousewheel={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
          keyboard={true}
          className={`${style.swiper} position-relative`}
        >
          {testimonials?.map((item, index) => (
            <SwiperSlide key={index}>
              <SwiperItem item={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </Grid>
  );
}
