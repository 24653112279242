import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Link from "next/link";
import MenuItem from "@mui/material/MenuItem";
import style from "./appbar.module.scss";
import { Grid, Menu } from "@mui/material";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { modalActions } from "../../../store/actions/modal.actions";
import { modalTypes } from "../../../store/types";
import Image from "next/image";

const MainAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const dispatch = useDispatch();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const router = useRouter();
  const showRegister = () => {
    router.push("https://affiliate.placebopartners.com/authentication/sign-up");
  };

  return (
    <AppBar className={`${style["bg-info"]} ${style.appbar}`} position="sticky">
      <Container maxWidth="lg">
        <Toolbar>
          <Typography
            onClick={() => {
              if (router.pathname !== "/") router.push("/");
            }}
            variant="h5"
            noWrap
            component="div"
            sx={{
              cursor: "pointer",
              fontWeight: "700",
              width: "260px",
              display: { xs: "none", md: "flex" },
            }}
          >
            <Image
              alt="Logo"
              width={200}
              height={100}
              src="/image/site-logo.png"
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                onClick={() => {
                  showRegister();
                  handleCloseNavMenu();
                }}
              >
                <Typography textAlign="center">Signup</Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            onClick={() => {
              if (router.pathname !== "/") router.push("/");
            }}
            variant="h6"
            noWrap
            component="div"
            sx={{
              cursor: "pointer",
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            Placebo Partners
          </Typography>

          <Grid
            container
            justifyContent="flex-end"
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            <Grid className={style["custom-btn"]}>
              <button onClick={showRegister} className={style.signup}>
                Sign up
              </button>
            </Grid>
            <Grid className={style["custom-btn"]}>
              <Link
                href={
                  "https://affiliate.placebopartners.com/authentication/sign-in"
                }
                key="login"
                sx={{
                  cursor: "pointer",
                  my: 2,
                  color: "white",
                  display: "block",
                }}
                passHref
              >
                <button className={style.login}>Login</button>
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default MainAppBar;
