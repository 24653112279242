import React from "react";

import Affiliates from "../src/component/Affiliates";
import Head from "next/head";
import GeneralLayout from "../layouts/GeneralLayout";
const AffiliatePage = () => {
  return (
    <>
      <Head>
        <title>Placebo Partners | Your Best Partner for Success</title>
      </Head>
      <Affiliates />
    </>
  );
};
AffiliatePage.layout = GeneralLayout;
export default AffiliatePage;
