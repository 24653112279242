import React from "react";
import useTransition from "../hooks/useTransition";

import Footer from "../src/component/Footer";
import MainAppBar from "../src/component/MainAppBar";

const GeneralLayout = ({ children }) => {
  useTransition(700);

  return (
    <>
      <MainAppBar />
      {children}
      <Footer />
    </>
  );
};

export default GeneralLayout;
