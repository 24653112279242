import React from "react";

import style from "./affiliate.module.scss";
import Image from "next/image";
import useTransition from "../../../hooks/useTransition";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import Testimonials from "../Testimonials";
import { useRouter } from "next/router";

const Affiliates = () => {
  useTransition(700);
  const router = useRouter();
  const showRegister = () => {
    router.push("https://affiliate.placebopartners.com/authentication/sign-up");
  };

  return (
    <Container className={style.container}>
      <Grid container className={`justify-content-center ${style.content}`}>
        <Grid item>
          <Box
            sx={{ typography: "subtitle2" }}
            className={style["primary-header"]}
          >
            Placebo Partners Affiliate program
          </Box>
        </Grid>
        <Grid item>
          <div className={style["sec-header"]}>
            Make money by referring people
          </div>
        </Grid>
        <Grid container>
          <div
            onClick={showRegister}
            className={`${style["sec-header"]} ${style["signup"]} ${style["no-select"]}`}
          >
            Signup Now!
          </div>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing="12"
          sx={{ flexGrow: 1 }}
        >
          {[1, 2, 3, 4].map((index) => (
            <Grid item xs={3} className={style.clover} key={index}>
              <Image
                width="120"
                height="120"
                alt="affiliate"
                src="/image/clover-affiliate.png"
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          sx={{ flexGrow: 1 }}
          direction={{ md: "column", lg: "row" }}
          spacing="1"
          justifyContent="center"
        >
          <Grid item xs={12} md={6} lg={4} className={style["item"]}>
            <Image
              width="80"
              height="80"
              alt="affiliate"
              src="/image/cooperate.png"
            />
            <div className={style["header"]}>Join</div>
            <div className={style["content"]}>
              join for free in just 1 minute and get a dedicated account
              manager!
            </div>
            <div className={style["learn-more"]}>learn more</div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className={style["item"]}>
            <Image
              width="80"
              height="80"
              alt="affiliate"
              src="/image/mic.png"
            />
            <div className={style["header"]}>advertise</div>
            <div className={style["content"]}>
              refer players to Placebo Partners casino websites and you will get
              up to 50 % lifetime profit!
            </div>
            <div className={style["learn-more"]}>learn more</div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} className={style["item"]}>
            <Image
              width="80"
              height="80"
              alt="affiliate"
              src="/image/bitcoin.png"
            />
            <div className={style["header"]}>Earn</div>
            <div className={style["content"]}>
              get paid every month with no negativ carry over!
            </div>
            <div className={style["learn-more"]}>learn more</div>
          </Grid>
        </Grid>
        <Grid container className={style["body"]}>
          <Box className={style["header"]}>CRYPTO AFFILIATES</Box>
          <div className={style["text"]}>
            Crypto is the future! And crypto affiliates get only the best of
            what the future brings at Placebo Partners. See in real time how
            your campaigns do in crypto media, and optimize performance . Track
            everything!
          </div>
          <div className={style["body-sec-header"]}>
            WHY BECOME A CRYPTO AFFILIATE WITH US
          </div>

          <div className={style["benefits"]}>
            <Box
              sx={{ display: { xs: "none", md: "block", lg: "block" } }}
              className={`${style.tailor}`}
            >
              <Image
                width="400"
                height="400"
                alt="Roulette"
                src={"/image/tailor.png"}
              />
            </Box>

            <div className={style["benefit-container"]}>
              <Box className={style["benefit"]}>
                <Grid container className={style["title"]}>
                  <span className={style["main"]}>50%</span>
                  <span className={style["secondary"]}>Commission!</span>
                </Grid>
                <div className={style["benefit-text"]}>
                  Commissions up to 50% per month
                </div>
              </Box>
              <Box className={style["benefit"]}>
                <Grid container className={style["title"]}>
                  <span className={style["main"]}>Early</span>
                  <span className={style["secondary"]}>Payouts!</span>
                </Grid>
                <div className={style["benefit-text"]}>
                  Payments in the very early days of each month.
                </div>
              </Box>
              <Box className={style["benefit"]}>
                <Grid container className={style["title"]}>
                  <span className={style["main"]}>Immediate</span>
                  <span className={style["secondary"]}>Notifications</span>
                </Grid>
                <div className={style["benefit-text"]}>
                  Commissions up to 50% per month
                </div>
              </Box>
              <Box className={style["benefit"]}>
                <Grid container className={style["title"]}>
                  <span className={style["main"]}>Real time</span>
                  <span className={style["secondary"]}>monitoring</span>
                </Grid>
                <div className={style["benefit-text"]}>
                  Commissions up to 50% per month
                </div>
              </Box>
              <Box className={style["benefit"]}>
                <Grid container className={style["title"]}>
                  <span className={style["main"]}>Special</span>
                  <span className={style["secondary"]}>crypto content</span>
                </Grid>
                <div className={style["benefit-text"]}>
                  Crypto articles, reviews, blog entries. Just ask us!
                </div>
              </Box>
              <Box className={style["benefit"]}>
                <Grid container className={style["title"]}>
                  <span className={style["main"]}>Crypto</span>
                  <span className={style["secondary"]}>expertice</span>
                </Grid>
                <div className={style["benefit-text"]}>
                  Placebo Partners has been among biggest casino platform
                  providers from the Day 1. We have a crypto expertise.
                </div>
              </Box>
            </div>
            <div className={`d-none d-lg-block ${style.fox}`}>
              <Image
                width="400"
                height="400"
                alt="Roulette"
                src={"/image/fox.png"}
              />
            </div>
          </div>
          <h2 className={style["commission-title"]}>COMMISSIONS</h2>
          <div className={style["commission-text"]}>
            You can earn amazing commissions of up to 50%!
          </div>
        </Grid>
        <Grid
          container
          direction={{ md: "column", lg: "row" }}
          className={style["revenue"]}
        >
          <Grid className={style["subject"]}>NET REVENUE</Grid>
          <Grid
            item
            lg={8}
            xs={12}
            justifyContent="center"
            className={style["revenue-container"]}
          >
            <div className={style["item"]}>
              <span className={style["amount"]}>25%</span>
              <span className={style["currency"]}>
                0-200 <sub>mBTC</sub>
              </span>
            </div>

            <div className={style["item"]}>
              <span className={style["amount"]}>30%</span>
              <span className={style["currency"]}>
                200-500<sub>mBTC</sub>
              </span>
            </div>
            <div className={style["item"]}>
              <span className={style["amount"]}>35%</span>
              <span className={style["currency"]}>
                500<sub>mBTC</sub> – 1<sub>BTC</sub>
              </span>
            </div>
            <div className={style["item"]}>
              <span className={style["amount"]}>40%</span>
              <span className={style["currency"]}>
                1 – 2<sub>BTC</sub>
              </span>
            </div>
            <div className={style["item"]}>
              <span className={style["amount"]}>45%</span>
              <span className={style["currency"]}>
                2 – 5<sub>BTC</sub>
              </span>
            </div>
            <div className={style["item"]}>
              <span className={style["amount"]}>50%</span>
              <span className={style["currency"]}>
                5<sub>BTC</sub> – ...
              </span>
            </div>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing="12"
            sx={{ flexGrow: 1 }}
          >
            <Testimonials />
          </Grid>
        </Grid>

        <Grid container className={`${style["footer-container"]}`}>
          <Image
            width="2600"
            height="500"
            alt="Roulette"
            src={"/image/city.png"}
          />

          <div className={style["footer-text"]}>
            No special offers for new affiliates by default! No negative
            carry-over
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Affiliates;
