import React from "react";
import style from "./testimonials.module.scss";
import Image from "next/image";
import Link from "next/link";

export default function SwiperItem({ item }) {
  return (
    <div className={style.container}>
      <div className={style.logo}>
        <Image
          width={150}
          height={70}
          src={item.img}
          alt={item.name}
          className={style.img}
        />
      </div>
      <div className={style.desc}>
        <p className={style.content}>
          {item.desc} -{" "}
          {item.link && item.name && <Link href={item.link}>{item.name}</Link>}
        </p>
      </div>
    </div>
  );
}
