import React from "react";
import style from "./footer.module.scss";
import BrandsList from "./BrandsList";
import CopyRights from "./CopyRights";
import Image from "next/image";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

const Footer = () => {
  return (
    <>
      <div className={`col-lg-12 float-left ${style.footerbg}`}>
        <div className={"container"}>
          <div className={"w-100 float-left"}>
            <BrandsList />
            <CopyRights />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
