const providers = [
    'evolution',
    'pragmatic',
    'amatic',
    'playson',
    'alg',
    'booming',
    'merkur',
    'wazdan',
    'booongo',
    'blueprint',
    'augustgaming',
    'evoplay',
    'ezugi',
    'mrslotty',
    'belatra',
    'igtech',
    'gameart',
    'habanero',
    'bsg',
    'platipus',
    'spinomenal',
    'lucky'
];

export default providers;
