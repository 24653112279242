import React from 'react';
import providers from './providers';
import style from './brand-list.module.scss';

export default function BrandContainers() {
    const brandSrc = 'https://cdn2.softswiss.net/logos/providers/white';
    return (
        <div className={style['brand-list']}>
            {providers.map((provider, index) => (
                <div className={style['brarnd-item']} key={index}>
                    <img alt={provider} src={`${brandSrc}/${provider}.svg`} />
                </div>
            ))}
        </div>
    );
}
