import React from "react";
import Image from "next/image";
import style from "./copyright.module.scss";
import { Grid } from "@mui/material";
import Link from "next/link";
export default function CopyRights() {
  return (
    <div>
      <div>
        <div className={style.copyright}>
          <Grid
            justifyContent="space-between"
            container
            className={style.header}
          >
            <Grid md={12} lg={10} item className={style["copyright-content"]}>
              <p className={style.text}>
                Copyright © 2021 PlaceboPartners. All Rights Reserved.
              </p>
              <Image
                width="32p"
                height="32p"
                src="https://cdn.casinobayhub.com/images/footer/18plus.png"
                alt=""
              />
            </Grid>
            <Grid item>
              <Link href="/terms">
                <a> Terms {"&"} Conditions</a>
              </Link>
            </Grid>
          </Grid>
          <Grid
            justifyContent="space-between"
            container
            className={style.content}
          >
            <div className={style.text}>
              <span>
                <p>
                  <a href="#">Placebo group</a> has been among the first
                  multi-currency online casino to offer play in major
                  Cryptocurrencies as Bitcoin, Litecoin and other
                  Cryptocurrencies.
                </p>
                <p>
                  We love Casino and we are players ourselves, that&apos;s why
                  we felt a professional casino focusing mainly customers were
                  missing in the market and we are here to fill in that gap and
                  be your most loved platform so please let us know what you
                  feel and what we can do to make it even better for you. we are
                  always available and happy to respond at{" "}
                  <a href="mailto:support@placebopartners.com">
                    support@placebopartners.com
                  </a>
                </p>
              </span>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
}
